<template>
<!--  :style="{backgroundImage: 'url('+bg+')'}"-->
    <div class="container-fluid  bg-img video-box" >
      <video class="video-background" :poster="bgImg" :src="bg" loop playsinline  muted="muted" autoplay="autoplay"></video>
      <div class="layer">
      </div>

    </div>
</template>

<script>
import { getConfig } from '@/axios/api'
export default {
  name: 'noticeComponent',
  data () {
    return {
      bgImg: require('../assets/img/imgs/bg.png'),
      bg: 'https://ihzdc.oss-cn-chengdu.aliyuncs.com/yuanlang/video/home_video.mp4',
      bgHeight: 'bg-height-885',
      show: true,
      newTitle: ''
    }
  },
  watch: {

  },
  mounted () {
    // this.getConfig()
  },
  created () {
  },
  methods: {
    getConfig () {
      if (sessionStorage.getItem('pageConfig')) {
        const data = JSON.parse(sessionStorage.getItem('pageConfig'))
        this.newTitle = data.new_msg[0].title
        // this.trans()
        return
      }
      getConfig().then(res => {
        this.newTitle = res.data.new_msg[0].title
        // this.trans()
      })
    },
    trans () {
      this.$nextTick(() => {
        // window.translate.request.api.host = 'https://tr.yuenlongbarrage.hk/' // 将这里面的ip地址换成你服务器的ip，注意开头，及结尾还有个 / 别拉下
        // // 指定翻译服务为使用 translate.service
        // window.translate.execute()// 进行翻译
      })
    }
  }

}
</script>

<style scoped lang="less">
a:hover{
  text-decoration: none;
  color: inherit;
}
a{
  text-decoration: none;
}
.video-box {
  position: relative;
  height: 100vh;
  background-color: #C1CFF7;
  overflow: hidden;
}

.video-box .video-background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 800px;
}

.layer{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
}
</style>
