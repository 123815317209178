import request from "./index";
import baseUrl from "@/utils/baseUrl";

export const getNavList = () => request.get(baseUrl + "nav/list");

export const getCate = (param) =>
  request.get(baseUrl + "cate/getCate?type_id=" + param.type_id);

export const getArticle = (param) =>
  request.get(baseUrl + "cate/article?type_id=" + param.id);

export const getBjNavs = () => request.get(baseUrl + "Config/getBjNavs/");

export const getConfig = () => request.get(baseUrl + "config/");

// export const getVideo = (param) => request.post(baseUrl + "video/index", param);

// export const communityListApi = (param) =>
//   request.post(baseUrl + "allapi/get_article_list_for_typeid", param);


export const getVideo = (param) => request.get(baseUrl + "video/index?limit="+param.limit+"&type="+param.type+"&page="+param.page);

export const communityListApi = (param) =>
  request.get(baseUrl + "allapi/get_article_list_for_typeid?typeid="+param.typeid+"&page="+param.page);